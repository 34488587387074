<template>
  <div class="app-footer">
    
   </div>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>

<style>

</style>