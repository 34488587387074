<template>
    <div class="app-contents">
        <div class="container">
            <div class="content-top">
                <p class="txt-h3">반가워요 용사여<br>우리 함께 어떤 미션을<br>성공시켜볼까요?</p>
            </div>
            <div class="content-main">
                <div class="mission-card">
                    <li class="yellow">
                        <p class="txt03 yellow">상쾌하게 산책하기</p>
                        <img src="../assets/images/module/mission-card01.png" alt="mission-card01">
                    </li>
                    <li class="purple">
                        <p class="txt03 purple">상쾌하게 산책하기</p>
                        <img src="../assets/images/module/mission-card01.png" alt="mission-card01">
                    </li>
                    <li class="yellow">
                        <p class="txt03 yellow">상쾌하게 산책하기</p>
                        <img src="../assets/images/module/mission-card01.png" alt="mission-card01">
                    </li>
                    <li class="import-btn">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </li>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Home',
    props: {
       
    }
}

</script>

<style>

</style>